import fp from 'lodash/fp';
import {fabric} from 'fabric';

import {Field} from './Field';

export class CheckboxField extends Field {
    static imgSrc = '/img/checkBox.png';

    get progressLabel() {
        if (this.filled) {
            if (this.data.value === 'true') {
                return 'Un-check';
            } else {
                return 'Next';
            }
        } else {
            return 'Check';
        }
    }

    toJSON(el) {
        let result = super.toJSON(el);
        let elProps = fp.pick(['top', 'left', 'scaleX', 'scaleY', 'angle'], el);
        result.field_data = {
            elProps,
        };
        return result;
    }

    draw() {
        return new Promise(resolve => {
            const box = new fabric.Rect({
                fill: null,
                stroke: 'black',
                strokeWidth: 3,
                field: this,
                width: 50,
                height: 50,
            });
            box.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
            });

            const checked = this.data.value === 'true';
            if (this.filled && checked) {
                const strokeWidth = 15;
                const check = new fabric.Path(
                    'M 125 150 L 150 175 L 200 125 L 150 175 z',
                    {
                        strokeWidth,
                        stroke: 'black',
                        top: box.top,
                        left: box.left,
                    },
                );
                // Scale check element independent of box
                // (Compensate for strokeWidth)
                check.scaleToHeight(
                    box.getScaledHeight() - strokeWidth * box.scaleY,
                    true,
                );
                const grp = new fabric.Group([box, check], {field: this});
                if (fp.isEmpty(this.data.field_data)) {
                    this.set(grp, {
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                        scaleX: this.data.coords.width / grp.width,
                        scaleY: this.data.coords.height / grp.height,
                    });
                } else {
                    this.set(grp, this.data.field_data.elProps);
                }
                grp.setControlsVisibility({
                    mt: false,
                    mb: false,
                    ml: false,
                    mr: false,
                });
                resolve(grp);
            } else {
                if (fp.isEmpty(this.data.field_data)) {
                    this.set(box, {
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                        scaleX: this.data.coords.width / box.width,
                        scaleY: this.data.coords.height / box.height,
                    });
                } else {
                    this.set(box, this.data.field_data.elProps);
                }
                this.set(box, {
                    backgroundColor: this.filled
                        ? undefined
                        : Field.UNFILLED_BACKGROUND,
                });
                box.setControlsVisibility({
                    mt: false,
                    mb: false,
                    ml: false,
                    mr: false,
                });
                resolve(box);
            }
        });
    }

    async click(ev, el, canvas) {
        // UNSET => TRUE => FALSE => UNSET
        if (!this.filled) {
            this.filled = true;
            this.data.value = 'true';
        } else if (this.data.value === 'true') {
            this.data.value = 'false';
        } else {
            this.filled = false;
            this.data.value = null;
        }
        const newEl = await this.draw();
        newEl.set(fp.pick(['top', 'left', 'angle', 'scaleX', 'scaleY'], el));
        canvas.add(newEl);
        canvas.remove(el);
        return this._postClick(ev, newEl, canvas);
    }
}
