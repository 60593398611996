import fp from 'lodash/fp';

import * as lib from '../lib';
import {Field} from '../fields';

export default class PageController {
    constructor(pageNumber) {
        this.pageNumber = pageNumber;
        this._log = new lib.NonceLog(`PageController(${pageNumber})`);
        this._log.log('new');
        this._dirty = false;
        this._canvas = null;
        this._initMutex = new lib.Mutex();
        // updated each render
        this._formController = null;
        this.page = null;
        this.options = {};
    }

    get formController() {
        return this._formController;
    }

    set formController(formController) {
        if (formController !== this._formController) {
            this._formController = formController;
            formController.registerPageController(this);
        }
    }

    get canvas() {
        return this._canvas;
    }

    set canvas(canvas) {
        this._canvas = canvas;
    }

    get canvasHeight() {
        return this.canvas?.height || 0;
    }

    get signatureValue() {
        return this.formController.signatureValue;
    }

    get ordersValue() {
        return this.formController.ordersValue;
    }

    get numIncomplete() {
        return fp.sum(
            fp.map(obj => (obj.field.filled ? 0 : 1), this.canvas.getObjects()),
        );
    }

    // TODO: is this dead code?
    scale = x => {
        const zoom = this.canvas?.getZoom() || 1;
        return zoom * x;
    };

    onReady = async canvas => {
        const log = this._log.sub('onReady');
        log.log('Enter onReady', {
            canvas,
            newCanvas: canvas !== this.canvas,
        });
        this.canvas = canvas;
        if (canvas) {
            await this.initPage();
        }
        log.log('Leave onReady');
    };

    initPage = async () => {
        return await this._initMutex.runExclusive(() => this._initPage());
    };

    _initPage = async () => {
        const log = this._log.sub('initPage');
        log.log('begin');
        const objectsToRemove = this.canvas.getObjects();
        const src = fp.get('attributes.imgSrc', this.page);
        if (!src || !this.canvas) {
            log.log('initPage failed b/c', {src, c: this.canvas});
            return;
        }
        const img = await lib.setCanvasBackgroundImage(this.canvas, src);
        log.log('background');
        lib.scaleCanvasToImage(this.canvas, img);
        // Add fields to canvas
        const fields = fp.map(fd => {
            const field = Field.fromFieldData(fd, {
                pageController: this,
            });
            return field;
        }, this.page.attributes.fields);
        log.log('fields created', {
            objectsToRemove,
            fields,
        });
        const elements = await Promise.all(
            fields.map(async field => {
                const el = await field.draw();
                return el;
            }),
        );
        log.log('fields drawn');
        this._removeEventHandlers();
        elements.forEach(el => this.canvas.add(el));
        this.canvas.remove(...objectsToRemove);
        this._addEventHandlers();
        this.canvas.requestRenderAll();
        this.formController.countIncomplete();
        log.log('exit');
    };

    _addEventHandlers = () => {
        this.canvas.on('object:added', () => {
            this._setDirty(true);
            this.formController.countIncomplete();
        });
        this.canvas.on('object:removed', () => {
            this._setDirty(true);
            this.formController.countIncomplete();
        });
        this.canvas.on('object:modified', () => {
            this._setDirty(true);
        });
        console.log('Added event handlers');
    };

    _removeEventHandlers = () => {
        this.canvas.off();
        console.log('Removed event handlers');
    };

    saveCanvas = async () => {
        this._log.log('saveCanvas begin');
        if (!this._dirty) {
            this._log.log("canvas: Not saving canvas, b/c it's not dirty", {
                state: this,
                dirty: this._dirty,
            });
            return;
        }
        const fields = this.canvas.getObjects().map(el => el.field.toJSON(el));
        const c = await new Promise(resolve =>
            this.canvas.clone(resolve, {
                enableRetinaScaling: true,
            }),
        );
        const blob = await lib.canvasToFullsizeBlob(c);
        await this.formController.handleSavePage(blob, fields, this.page);
        this._setDirty(false);
        this._log.log('saveCanvas complete');
    };

    clearActiveObjects = () => {
        this.canvas.discardActiveObject();
    };

    getSortedObjects = () => {
        const objects = this.canvas.getObjects();
        const sortedObjects = objects.toSorted((a, b) => {
            if (a.top === b.top) {
                return a.left - b.left;
            } else {
                return a.top - b.top;
            }
        });
        return sortedObjects;
    };

    setCurrent = el => {
        this.formController.setCurrent(this.pageNumber, el);
    };

    handleClickNext = element => {
        return this.formController.handleClickNext({
            pageNumber: this.pageNumber,
            element,
        });
    };

    handleEditOrders = () => {
        return this.formController.handleEditOrders();
    };

    handleDrop = async ev => {
        ev.preventDefault();
        const {canvas} = this;
        const data = JSON.parse(ev.dataTransfer.getData('application/json'));
        const {id, type, offset} = data;
        const pointer = canvas.getPointer(ev);

        const field_data = {
            elProps: {
                left: pointer.x - offset.x,
                top: pointer.y - offset.y,
                scaleX: 1,
                scaleY: 1,
                angle: 0,
                // h & w are added later
            },
        };
        const field = Field.fromFieldData(
            {id, type, value: null, filled: false, field_data},
            {pageController: this},
        );
        const el = await field.draw();
        field.set(el, {
            left: pointer.x - el.width * offset.x,
            top: pointer.y - el.height * offset.y,
        });
        canvas.add(el);
        canvas.setActiveObject(el);
        return el;
    };

    handleClick = async ev => {
        const canvas = this.canvas;
        const element = canvas.findTarget(ev);
        if (!element) return;
        const field = element.field;
        await field.click(ev, element, canvas);
        this.canvas.requestRenderAll();
    };

    handleKeyDown = async ev => {
        this._log.log('handleKeyDown', ev.key, ev.keyCode);
        if (ev.key === 'Backspace' || ev.key === 'Delete') {
            const objs = this.canvas.getActiveObjects();
            this.canvas.remove(...objs);
        }
    };

    _setDirty = value => {
        this._dirty = value;
        this._log.log('_setDirty', {
            state: this,
            dirty: this._dirty,
        });
    };
}
