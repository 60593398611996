import fp from 'lodash/fp';
import {fabric} from 'fabric';

import {Field} from './Field';

export class OrdersField extends Field {
    static imgSrc = '/img/ordersBox.png';

    constructor(data, options) {
        super(data, options);
        if (this.pageController.ordersValue) {
            this.filled = true;
        }
    }

    get progressLabel() {
        if (this.filled) {
            return 'Next';
        } else {
            return 'Enter orders';
        }
    }

    toJSON(el) {
        let result = super.toJSON(el);
        const elProps = fp.pick(
            ['top', 'left', 'width', 'scaleX', 'scaleY', 'angle'],
            el,
        );
        result.field_data = {
            elProps,
        };
        return fp.set('value', el.text, result);
    }

    draw() {
        return new Promise(resolve => {
            if (this.filled) {
                const ticketOrders = this.pageController.ordersValue;
                var el = new fabric.Textbox(
                    ticketOrders || 'NO ORDERS ENTERED',
                    {fontSize: 40, editable: false},
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                if (fp.isEmpty(this.data.field_data)) {
                    this.set(el, {
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                        width: this.data.coords.width,
                        backgroundColor: undefined,
                    });
                } else {
                    this.set(el, {
                        ...this.data.field_data.elProps,
                        backgroundColor: undefined,
                    });
                }
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    OrdersField.imgSrc,
                    el => {
                        el.setControlsVisibility({
                            mt: false,
                            mb: false,
                        });
                        if (fp.isEmpty(this.data.field_data)) {
                            this.set(el, {
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                                left: this.data.coords.left,
                                top: this.data.coords.top,
                            });
                        } else {
                            this.set(el, {
                                ...fp.omit(
                                    ['width'],
                                    this.data.field_data.elProps,
                                ),
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                            });
                        }
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev, el, canvas) {
        const text = await this.pageController.handleEditOrders();
        if (fp.isUndefined(text)) return; // cancel

        this.filled = !fp.isEmpty(text);
        this.data.value = text;
        const newEl = await this.draw();
        if (this.filled) {
            const scale = el.scaleY;
            const width = el.scaleX * el.width;
            newEl.set({
                ...fp.pick(['top', 'left', 'angle'], el),
                scaleX: scale,
                scaleY: scale,
                width,
            });
        } else {
            const scaleY = el.scaleY;
            const scaleX = el.width / newEl.width;
            newEl.set({
                ...fp.pick(['top', 'left', 'angle', 'scaleY'], el),
                scaleX,
                scaleY,
            });
        }
        canvas.add(newEl);
        canvas.remove(el);
        // this.set(newEl, {backgroundColor: undefined});
        return this._postClick(ev, newEl, canvas);
    }
}
