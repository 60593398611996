import fp from 'lodash/fp';
import {fabric} from 'fabric';

import {Field} from './Field';

export class TextboxField extends Field {
    static imgSrc = '/img/textBox.png';

    constructor(data, options) {
        super(
            fp.merge(
                {
                    value: 'ENTER TEXT',
                },
                data,
            ),
            options,
        );
    }

    get progressLabel() {
        if (this.filled) {
            return 'Next';
        } else {
            return 'Enter text';
        }
    }

    toJSON(el) {
        let result = super.toJSON(el);
        const elProps = fp.pick(
            ['top', 'left', 'width', 'scaleX', 'scaleY', 'angle'],
            el,
        );
        result.field_data = {
            elProps,
        };
        return fp.set('value', el.text, result);
    }

    draw() {
        return new Promise(resolve => {
            if (this.filled) {
                var el = new fabric.Textbox(
                    this.data.value || 'NO TEXT ENTERED',
                    {fontSize: 40, editable: true},
                );
                el.setControlsVisibility({
                    mt: false,
                    mb: false,
                });
                if (fp.isEmpty(this.data.field_data)) {
                    this.set(el, {
                        left: this.data.coords.left,
                        top: this.data.coords.top,
                        width: this.data.coords.width,
                    });
                } else {
                    this.set(el, {
                        ...this.data.field_data.elProps,
                        backgroundColor: undefined,
                    });
                }
                el.on('editing:exited', () => {
                    this.handleEditingExited(el);
                });
                resolve(el);
            } else {
                fabric.Image.fromURL(
                    TextboxField.imgSrc,
                    el => {
                        el.setControlsVisibility({
                            mt: false,
                            mb: false,
                        });
                        if (fp.isEmpty(this.data.field_data)) {
                            this.set(el, {
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                                left: this.data.coords.left,
                                top: this.data.coords.top,
                            });
                        } else {
                            this.set(el, {
                                ...fp.omit(
                                    ['width'],
                                    this.data.field_data.elProps,
                                ),
                                backgroundColor: Field.UNFILLED_BACKGROUND,
                            });
                        }
                        resolve(el);
                    },
                    {crossOrigin: 'Anonymous'},
                );
            }
        });
    }

    async click(ev, el, canvas) {
        if (this.filled) {
            this.pageController.setCurrent(ev.element);
            return el;
        } // else...
        this.filled = true;
        const newEl = await this.draw();
        const scale = el.scaleY;
        const width = el.scaleX * el.width;
        newEl.set({
            ...fp.pick(['top', 'left', 'angle']),
            scaleX: scale,
            scaleY: scale,
            width,
        });
        canvas.add(newEl);
        canvas.remove(el);
        newEl.enterEditing();
        newEl.selectAll();
        this.pageController.setCurrent(newEl);
        canvas.setActiveObject(newEl);
        this.set(newEl, {backgroundColor: undefined});
        return newEl;
    }

    async handleEditingExited(el) {
        if (el.text.trim() === '') {
            console.log('Empty text');
            this.filled = false;
            this.data.value = null;
            const newEl = await this.draw();
            const scaleY = el.scaleY;
            const scaleX = el.width / newEl.width;
            newEl.set({
                ...fp.pick(['top', 'left', 'angle', 'scaleY'], el),
                scaleX,
                scaleY,
            });
            this.pageController.canvas.add(newEl);
            this.pageController.canvas.remove(el);
        }
    }
}
